/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka&display=swap');
body{
    margin:0;
    padding:0;
}
#f1{
    font-family: 'Cairo', sans-serif;
}
#f2{
    font-family: 'Poppins', sans-serif;
}
#c1{
    min-width: 250px;
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #353131;
    color: #353131;
    box-shadow: 9999px 0 0 -5px #353131;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }
  
  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #353131;
    color: #353131;
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #353131;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #353131;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #353131;
    }
    30% {
      box-shadow: 9984px 0 0 2px #353131;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #353131;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #353131;
    }
    30% {
      box-shadow: 9999px 0 0 2px #353131;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #353131;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #353131;
    }
    30% {
      box-shadow: 10014px 0 0 2px #353131;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #353131;
    }
  }
  #f3{
    font-family: 'Mochiy Pop P One', sans-serif;
  }
  #f4{
    font-family: 'Work Sans', sans-serif;
  }
  #f5{
    font-family: 'Lato', sans-serif;
  }
  #f6{
  font-family: 'Fredoka', sans-serif;

  }
  .wrapper {

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    strokeWidth: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    strokeWidth: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}
#c2{
  min-width: 250px;
  background-color: #1e2022;
}
.ss:input{
  background: #000;
}
#ddos{
  background:
  linear-gradient(rgb(0, 0, 0), transparent),
  linear-gradient(to top left, rgb(0, 13, 37), transparent),
  linear-gradient(to top right, rgb(4, 29, 14), transparent),
  linear-gradient(to bottom left, rgb(21, 1, 44), transparent),
  linear-gradient(to bottom right, rgb(37, 35, 54), transparent);

background-blend-mode: screen;
font-family: 'Fredoka', sans-serif;

} 
#greencard{
  background:
  linear-gradient(rgb(0, 0, 0), transparent),
  linear-gradient(to top left, rgb(1, 14, 37), transparent),
  linear-gradient(to top right, rgb(32, 32, 0), transparent),
  linear-gradient(to bottom left, rgb(1, 48, 41), transparent),
  linear-gradient(to bottom right, rgb(4, 40, 61), transparent);

background-blend-mode: screen;
font-family: 'Fredoka', sans-serif;

}  
#afford{
  background:
  linear-gradient(rgb(0, 0, 0), transparent),
  linear-gradient(to top left, rgb(0, 13, 37), transparent),
  linear-gradient(to bottom left, rgb(4, 29, 14), transparent),
  linear-gradient(to top right, rgb(30, 3, 61), transparent),
  linear-gradient(to bottom right, rgb(37, 35, 54), transparent);

background-blend-mode: screen;
font-family: 'Fredoka', sans-serif;

}  
#dp20{
  --value:50;
}